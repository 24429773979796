@font-face {
  font-family: 'Manrope';
  src: url('../assets/fonts/Manrope-Regular.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

* {
  font-family: 'Manrope';
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  font-style: normal;
  -webkit-tap-highlight-color: transparent;
  list-style: none;
  text-decoration: none !important;
}

.tracking-body {
  min-height: 100vh;
  width: 100%;
  background: #f5f5f5;
  padding: 40px 16px 24px;
}

.tracking-container {
  max-width: 600px;
  margin: auto;
  margin-bottom: 48px;

  &-status {
    background: #ffffff;
    box-shadow: 0px 0px 56px rgba(208, 207, 207, 0.08);
    border-radius: 16px;
    min-height: 100px;
    padding: 16px;
    padding-bottom: 24px;
    margin-bottom: 16px;
    margin-top: 16px;

    &-text {
      display: flex;
      flex-direction: column;
      row-gap: 24px;
    }

    &-estimate {
      & h1 {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
      }

      & h6 {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #6b6767;
        margin-bottom: 4px;
        margin-top: 16px;
      }

      & p {
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        color: #150e0f;
        margin-bottom: 0px;
      }
    }

    &-address {
      & h5 {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #6b6767;
        margin-bottom: 8px;
      }

      & h6 {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #352f30;
        margin-bottom: 4px;
      }

      & p {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #989495;
        margin: 0px;
      }
    }
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 0px 0px 16px;
    // border-bottom: 1px solid #b3b0b1;
    flex-direction: column;
    background: white;
    width: 100%;

    border-radius: 16px;

    &-logo {
      padding: 20px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    &-nav {
      padding: 12px 0px;
      width: 100%;
      border-top: 0.8px solid #ebebeb;

      &-list {
        max-width: 420px;
        margin: auto;
        display: flex;
        justify-content: center;
      }

      &-divider {
        min-height: 100%;
        border-left: 0.8px solid #dfdede;
        margin: 0px 35.39px;
      }

      &-item {
        &-text {
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          margin: 0;
          color: #5d5758;

          &:hover {
            color: #5d5758;
          }

          &:focus {
            color: #5d5758;
          }

          &:active {
            color: #5d5758;
          }
        }
      }
    }

    &-image {
      max-width: 133px;
      max-height: 48px;
    }

    & h1.tracking-container-header-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      margin: 0;
      color: #241d1e;
      text-align: center;
    }

    & h1 {
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      color: #000000;
      max-width: calc(100% - 48px);
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &-socials {
      display: flex;
      column-gap: 8px;

      &-link {
        background: white;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        background: #ffffff;
        border: 1px solid #dfdede;
        border-radius: 8px;
      }
    }
  }
}

.tracking-content {
  background: #ffffff;
  box-shadow: 0px 45px 24px rgba(159, 145, 148, 0.08);
  border-radius: 16px;
  width: 100%;
  min-height: 184px;
  padding: 32px 16px 24px;
  margin-bottom: 16px;
  margin-top: 16px;

  & .tracking-content-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    margin-bottom: 12px;
  }

  & p {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #504b4b;
    margin-bottom: 32px;
  }

  &-form {
    display: flex;
    column-gap: 16px;
    align-items: center;
    justify-content: center;

    & input {
      height: 44px;
      border: 1px solid #ebebeb;
      border-radius: 5px;
      width: calc(100% - 83px);
      max-width: 400px;
      outline: none !important;
      padding: 0px 16px;

      &:focus {
        border: 1px solid #504b4b;
      }

      &::placeholder {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #c2c0c0;
      }
    }

    & button {
      width: 67px;
      height: 44px;
      background: #090102;
      border-radius: 5px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;
      outline: none !important;
      border: none;
      box-shadow: none;
    }
  }
}

.tracking-share-card {
  width: 100%;
  min-height: 100px;
  background: #090102;
  box-shadow: 0px 45px 24px rgba(159, 145, 148, 0.08);
  border-radius: 16px;
  max-width: 600px;
  padding: 16px;
  background-image: url('../assets/images/bg.svg');
  background-repeat: no-repeat;
  background-position: -95px;

  &-text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 24px;

    & .tracking-share-card-brand-text {
      max-width: 192px;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #ffffff;
      display: flex;
      flex-direction: column;
    }

    & img {
      width: 32px;
      height: 32px;
    }
  }

  & a {
    display: flex;

    & span {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #ffffff;
      display: flex;
      column-gap: 8px;
    }
  }
}

.tracking-footer {
  position: fixed;
  bottom: 24px;
  right: 16px;
  height: 32px;
  padding: 8px 12px;
  background: #090102;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;

  & span {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
    margin: 0;
  }
}

.status-toaster-icon {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

.toaster-text {
  margin: 0;

  & div {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    margin: 0;
  }
}

.close-toaster-icon,
.close-toaster-icon span {
  height: 16px !important;
  width: 16px !important;
  margin-left: 8px;
  cursor: pointer;
}

.close-toaster-icon.toaster-icon,
.close-toaster-icon span {
  margin-left: 0px !important;
}

.tracking-content-history {
  background: white;
  max-height: 400px;
  box-shadow: 0px 0px 56px rgba(208, 207, 207, 0.08);
  border-radius: 16px;
  padding: 16px;
}

.tracking-content-history>p {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #241d1e;
  margin-bottom: 16px;
}

.tracking-content-footer.no-margin {
  margin-top: 0px !important;
}

.tracking-content-footer {
  border: 1px solid #dfdede;
  border-radius: 8px;
  min-height: 52px;
  padding: 10px 12px;
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 400px) {
    row-gap: 16px;
  }

  @media (max-width: 320px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.tracking-content-footer>div {
  display: flex;
  column-gap: 8px;
}

.tracking-content-footer>div>div {
  & .courier-name {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    margin: 0;
  }

  & .courier-id {
    display: flex;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #150e0f;
    margin-top: 0px;

    & .id {
      display: flex;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #150e0f;
      margin-top: 0px;
    }
  }
}

.courier-id>span:first-of-type {
  margin-right: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #6b6767;
  margin-top: 2px;
  white-space: nowrap;
}

.courier-id>span:last-of-type,
.courier-id>span:last-of-type img {
  width: 16px;
  height: 16px;
  display: flex;
}

.courier-id>span:last-of-type {
  margin-left: 8px;
}

.tracking-content-contact-flex {
  column-gap: 8px;
}

.tracking-content-flex {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.tracking-content-footer img {
  width: 32px;
  height: 32px;
}

.tracking-content-history-list {
  max-height: 225px;
  min-height: 225px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  row-gap: 18px;
  position: relative;

  &-item {
    display: grid;
    grid-template-columns: 85px calc(100% - 130px);
    column-gap: 40px;
  }
}

.history {
  &-date {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin: 0px;
    color: #9a9a9a;
  }

  &-time {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin: 0px;
    color: #9a9a9a;
  }

  &-location {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
  }

  &-message {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #9a9a9a;
    margin-top: 4px;
  }
}

.tracking-history-more {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 70px;
  background: linear-gradient(360deg,
      #ffffff 42.86%,
      rgba(255, 255, 255, 0) 127.14%);
  display: flex;
  column-gap: 8px;
}

.tracking-history-less {
  display: flex;
  justify-content: center;
  align-items: center;

  & p {
    display: flex;
    column-gap: 8px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #504b4b;
  }

  & img {
    transform: rotate(180deg);
  }
}

.tracking-history-more {
  display: flex;
  justify-content: center;
  align-items: center;

  & p {
    display: flex;
    column-gap: 8px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #504b4b;
  }
}

.tracking-progress {
  height: 12px;
  background: #ebebeb;
  border-radius: 500px;
  margin-top: 24px;
}

.tracking-progress-bar {
  height: 12px;
  position: relative;
  border-radius: 500px;

  &-circle {
    width: 32px;
    height: 32px;
    border-radius: 500px;
    position: absolute;
    right: -4px;
    bottom: -75%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.tracking-progress-bar.cancelled-progress-bar {
  height: 12px;
  position: relative;
  border-radius: 500px;

  img {
    position: absolute;
    right: -4px;
    bottom: -50%;
  }
}

.tracking-items {
  min-height: 100px;
  background: #ffffff;
  box-shadow: 0px 0px 56px rgba(208, 207, 207, 0.08);
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & h6 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    margin-bottom: 24px;
    color: #241d1e;
  }
}

.tracking-items-list-title {
  display: grid;
  grid-template-columns: 32px calc(100% - 56px);
  column-gap: 24px;
  margin-bottom: 8px;

  & p {
    margin: 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #7a7676;
  }
}

.tracking-items-list-item {
  display: grid;
  grid-template-columns: 32px calc(100% - 56px);
  column-gap: 24px;
  row-gap: 8px;

  & p {
    margin: 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #423b3c;
  }
}

.tracking-container-feedback {
  background: white;
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  box-shadow: 0px 0px 56px rgba(208, 207, 207, 0.08);
  border-radius: 16px;
  margin-bottom: 16px;

  &-left {
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    & h4 {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
      margin: 0;
    }

    & p {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      margin: 0px;
      color: #7a7676;
    }
  }

  &-right {
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    &p {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      margin: 0;
      color: #7a7676;
    }
  }
}

.tracking-container-feedback-right>span {
  display: flex !important;
  column-gap: 8px;
}

.tracking-container-feedback-right>span img {
  height: 24px !important;
  width: 24px !important;
}

.rating-content>span {
  display: flex !important;
  column-gap: 8px;
}

.rating-line {
  margin: 24px 0px;
  border-top: 1px solid #dfdede;
}

.tracking-textarea>label {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #241d1e;
  margin-bottom: 8px;
  display: flex;
}

.tracking-textarea {
  margin-bottom: 40px;
}

.tracking-textarea textarea {
  border: 1px solid #ebebeb;
  border-radius: 5px;
  width: 100%;
  min-height: 84px;
  padding: 12px 16px;
  resize: none;
  outline: none !important;

  &::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #c2c0c0;
  }

  &:focus {
    border: 1px solid #504b4b;
  }
}

.rating-content>p {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 8px;
}

.rating-content>span img {
  height: 40px !important;
  width: 40px !important;
}

.tracking-error-container .tracking-share-card-text,
.home-container .tracking-share-card-text {
  flex-direction: row !important;
}

.tracking-container-header.tracking-center {
  justify-content: center;

  & h1 {
    width: unset;
  }
}

.tracking-page-error {
  background: #ffffff;
  box-shadow: 0px 0px 56px rgba(208, 207, 207, 0.08);
  border-radius: 16px;
  min-height: 295px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  margin-top: 16px;

  @media (min-width: 744px) {
    padding: 44px 48px 24px;
  }

  & .tracking-page-error-button {
    display: flex;
    justify-content: center;
    align-items: center;

    & button {
      width: 105px;
      height: 32px;
      outline: none !important;
      background: #ffffff;
      border: 1px solid #dfdede;
      border-radius: 8px;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #5d5758;
    }
  }

  & h1 {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    margin-bottom: 12px;
  }
}

.tracking-page-error>p {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #504b4b;
  margin-bottom: 48px;
}

.tracking-choice {
  margin-top: 32px;
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  & p {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    margin: 0px 8px;
    color: #5d5758;
  }

  & div {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #dfdede;
  }
}

.loading-body .tracking-container-header {
  & .react-loading-skeleton-container {
    display: flex;
    column-gap: 4px;
  }
}

.loading-flex-right>.loading-share {
  display: none;
}

.loading-flex {
  display: flex;
  flex-direction: column;
}

@media (max-width: 744px) {
  .tracking-items.share-bg.bg-image {
    background-image: url('../assets/images/contact-bg.svg');
    background-repeat: no-repeat;
  }

  .tracking-content-history {
    height: 100%;

    &-list {
      min-height: unset;
    }
  }

  .tracking-content-history-list.hidden {
    display: flex !important;
  }
}

@media (max-width: 743px) {
  .tracking-content-history-list.bg {
    display: none !important;
  }
}

.tracking-history-less.hidden {
  display: none !important;
}

@media (min-width: 744px) {
  .tracking-content-history-list.hidden {
    display: none !important;
  }
}

@media (min-width: 744px) {
  .tracking-container-status-text {
    flex-direction: row;
    justify-content: space-between;
  }

  .tracking-content-history-list {
    padding-right: 8px;
  }

  .tracking-content-history-list::-webkit-scrollbar {
    width: 9px;
    margin: 10px;
  }

  .tracking-content-history-list::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #d9d9d9;
    -webkit-border-radius: 12px;
    border-radius: 12px;
    padding: 8px;
    background: #d9d9d9;
  }

  .tracking-content-history-list::-webkit-scrollbar-thumb {
    -webkit-border-radius: 15px;
    border-radius: 15px;
    background: white;
    -webkit-box-shadow: inset 0 0 6px white;
    border: 3px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }

  .tracking-container-feedback {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .tracking-content-flex>.tracking-share-card {
    display: none;
  }

  .tracking-items-flex {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  .tracking-share-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .tracking-share-card-text {
    flex-direction: column-reverse;
    row-gap: 12px;
  }

  .tracking-items-flex>div {
    height: 100% !important;
    min-height: 165px !important;
  }

  .tracking-content-flex {
    flex-direction: row;
    column-gap: 16px;
  }

  .tracking-content-flex>.tracking-content-history {
    // max-width: 367px;
    width: 100%;
  }

  .tracking-container-feedback-right {
    align-items: flex-end;
  }

  .loading-flex {
    display: flex;
    column-gap: 16px;
    flex-direction: row;

    & span {
      width: 100%;
      display: flex;
      margin-bottom: 0px !important;
    }
  }

  .loading-flex>.loading-share {
    display: none;
  }

  .loading-flex>.loading-history {
    min-width: 365px;
    max-width: 365px;

    & span {
      height: 345px;
    }
  }

  .loading-flex-right {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    & span {
      height: 100% !important;
    }

    & .loading-share {
      display: flex;
    }
  }

  .loading-flex-right>span {
    min-width: 217px;
  }

  .tracking-items-flex>.tracking-share-card {
    display: flex !important;
  }
}

.tracking-items-flex>.tracking-share-card {
  display: none;
}

.rating-container {
  max-width: 544px;
  max-height: 448px;
  background: white;
  border-radius: 12px;
  padding: 24px 16px;
  width: 100%;

  @media (min-width: 744px) {
    padding: 32px 24px;
  }
}

.share-container {
  max-width: 544px;
  max-height: 655px;
  background: white;
  border-radius: 12px;
  padding: 24px 16px;
  width: 100%;

  @media (min-width: 744px) {
    padding: 32px 24px;
  }
}

.tracking-items-socials a:active,
.tracking-items-socials a:focus,
.tracking-items-socials a:hover,
.tracking-items-socials a:focus-visible {
  color: transparent;
}

.discard-content {
  padding: 16px;
}

.rating-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;

  & h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
  }
}

.rating-button {
  width: 100%;
  height: 44px;
  background: #d83854;
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  outline: none !important;
  border: none;
  box-shadow: none;
}

.pointer {
  cursor: pointer;
}

.tracking-content-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  max-width: 296px;
  margin: auto;
  margin-top: 16px;
  text-align: center;
}

.share-text {
  display: flex;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  column-gap: 8px;
  cursor: pointer;
}

.rating-header.share-header {
  margin-bottom: 8px;
}

.share-subtitle {
  margin-bottom: 24px;
}

.share-content>span {
  width: 100%;
  display: flex;
  margin-top: 24px;
  margin-bottom: 40px;
}

.share-content>span>img {
  width: 100%;
}

.share-flex {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;

  @media (max-width: 744px) {
    flex-direction: column;
  }
}

.brand-banner-tracking {
  margin-top: 16px;
}

.package-status-label {
  max-width: 70px;
  margin-right: auto;
  margin: 0 0 0 auto;
}

.package-status-time {
  display: flex;
  column-gap: 4px;

  & .history-time {
    white-space: nowrap;
  }

  & .history-date {
    white-space: nowrap;
  }
}

.share-flex>button {
  width: 100%;
  height: 56px;
  outline: none !important;

  background-color: #f5f5f5 !important;
  border-radius: 8px;
  display: flex;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #241d1e !important;
  column-gap: 8px;
  justify-content: center;
  align-items: center;

  @media (min-width: 744px) {
    display: flex;
    max-width: 240px;
  }
}

.share-flex>button.whatsapp-button {
  background-color: #d2ffdc !important;
}

.tracking-content-history-list.hidden {
  overflow: hidden;
}

.tracking-history-more {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 8px;

  @media (min-width: 744px) {
    display: none !important;
  }
}

.tracking-content-history-list {
  @media (max-width: 744px) {
    max-height: unset;
  }

  &.bg {
    @media (max-width: 744px) {
      min-height: 225px;
      max-height: 225px;
    }
  }
}

.tracking-content-history {
  @media (max-width: 744px) {
    max-height: unset;
  }
}

.tracking-items-socials {
  display: flex;
  column-gap: 8px;
}

.tracking-courier-info div {
  display: flex;
  column-gap: 8px;
  align-items: center;

  & img {
    height: 32px;
  }
}

.tracking-courier-info {
  display: flex;
  flex-direction: column;
  row-gap: 1px;
}

.tracking-courier-info .courier-code {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #150e0f;
  max-width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  align-items: center;
  margin-top: 1px;
}

.tracking-courier-info .courier-id {
  display: flex;
}

button:disabled {
  background-color: #c2c0c0;
  opacity: 1;
}

.red-banner {
  background: #f0504f;
  width: 100%;
  height: 60px;
  color: #fff;
  padding: 10px 30px;
  margin-bottom: 0px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

.tracking-share-card.share-bg.bg-image {
  background-image: url('../assets/images/bg-small.svg');
  background-position: 15px 13px;
}

.tracking-items.share-bg.bg-image {
  background-image: url('../assets/images/contact-bg-small.svg');
  background-repeat: no-repeat;
  background-position: 12px 25px;
}

.tracking-shop {
  background: white;
  padding: 16px;
  margin-top: 24px;
  border-radius: 12px;

  &-text {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    text-align: center;
    color: #241d1e;
    margin: auto;

    & span {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &-flex {
    display: flex;
    column-gap: 16px;
    margin-top: 24px;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 16px;

    @media (max-width: 400px) {
      align-items: center;
      row-gap: 24px;
    }

    &-item {
      width: 100%;
      min-width: 140px;
      max-width: 140px;
      min-height: 120px;
      max-height: 120px;
      border-radius: 15px;

      // @media (max-width: 400px) {
      //   max-width: ;
      //   max-height: 120px;
      // }

      & a {
        display: flex;
        width: 100%;
        height: 100%;

        & img {
          width: 100%;

          border-radius: 15px;

          @media (max-width: 400px) {
            max-height: 120px;
            object-fit: cover;
          }
        }
      }
    }
  }
}

.tracking-container-title {
  text-align: center;
}

.tracking-default-container-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #b3b0b1;
  padding-bottom: 16px;

  & p {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    margin: 0;
    color: #000000;
  }
}

.tracking-delayed-message-container {
  border-radius: 16px;
  background: #FEFEDF;
  box-shadow: 0px 0px 56px 0px rgba(208, 207, 207, 0.08);
  display: grid;
  gap: 16px;
  padding: 16px;
  margin-top: 16px;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &>img {
      cursor: pointer;
    }

    & div {
      display: flex;
      justify-content: center;
      align-items: center;



      &>p {
        color: #19191E;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin: 0;
      }


      & div {
        width: 24px;
        height: 24px;
        border-radius: 500px;
        background: #FFFF6B;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 8px;

        & img {
          width: 16px;
          height: 16px;

        }
      }
    }
  }

  &-line {
    width: 100%;
    border: 1px solid #F2F2D3;
  }

  &-text {
    border-radius: 16px;
    background: #F4F4CF;
    padding: 16px;

    & p {
      color: #302C2C;
      font-size: 14px;
      font-family: Manrope;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin: 0;
    }
  }
}

.tracking-content-flex.column {
  flex-direction: column !important;

  & .tracking-share-card-text {
    flex-direction: row !important;
  }
}

.tracking-content-flex.column>.tracking-content-history {
  max-width: unset !important;
}

.tracking-content-flex.column>.tracking-items-flex>div {
  min-height: unset !important;
}

.tracking-content-flex.column .tracking-content-history-list.bg {
  min-height: unset !important;
}

.banner-container {
  display: flex;
  width: 100%;
  background: white;
  height: 150px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
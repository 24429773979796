.tracking-container-header-nav .tracking-container-header-nav-item .tracking-container-header-nav-item-text {
    color: #5D5758;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
}

.tracking-body .tracking-content-history {
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 0px 45.82666778564453px 0px rgba(208, 207, 207, 0.08);

    &>p {
        color: #25252D;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 16px;
    }

    &>.tracking-content-history-list {
        // max-height: 200px;
        row-gap: 16px;

        & .package-status-time {
            flex-direction: column;
        }
    }
}

.tracking-body .tracking-container-status {

    margin: 20px 0px;
    border-radius: 12px;
    background: #FFF;
    padding: 16px 16px 20px 16px;
    box-shadow: 0px 0px 45.82666778564453px 0px rgba(208, 207, 207, 0.08);

    & .tracking-container-status-text {
        align-items: flex-start;

        @media (max-width: 743px) {
            align-items: flex-start;
        }

        & .tracking-container-status-estimate {
            & h1 {
                color: #000;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 30px;
            }

            & h6 {
                color: #74747A;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                margin: 0;
            }

            & p {
                color: #2B2B34;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;
            }
        }

        & .tracking-container-status-address {
            & h5 {
                color: #74747A;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                margin: 0;
            }

            & h6 {
                color: #2B2B34;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;
                margin: 0;
            }

            & p {
                color: #74747A;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
            }
        }
    }
}

.branding-container-email .branding-container-tracking-right-laptop-body-content-shop.preview-shop {
    background-color: white;
    margin-bottom: 0px;
}

.preview-shop-message {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 16px 0px 16px;


    &>div {
        display: flex;
        cursor: pointer;

        &>div:first-of-type {
            margin-right: 16px;
        }
    }

    &>p {
        color: var(--night-black-100, #25252D);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }
}

.preview-shop .slick-slide {
    // margin-right: 12px;
    min-width: 150px;
    max-width: 150px;
    height: 150px;
    margin-right: 12px;

    &>div {
        width: 150px;
        height: 100%;

        &>a {
            & div {
                height: 100%;

                & img {
                    height: 100%;
                }
            }
        }
    }

    &:last-of-type {
        margin-right: 0px;

        &>div {
            margin-right: 0px;
        }
    }

    &>div {
        display: flex;
        margin-right: 12px;



        & .branding-container-tracking-right-laptop-body-content-shop-flex-item.tracking-shop-item:last-of-type {
            margin-right: 0px;
        }

    }
}

.preview-shop-message {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 16px 0px 16px;


    &>div {
        display: flex;
        cursor: pointer;

        &>div:first-of-type {
            margin-right: 16px;
        }
    }

    &>p {
        color: var(--night-black-100, #25252D);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }
}

.preview-shop-message {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 16px 0px 16px;


    &>div {
        display: flex;
        cursor: pointer;

        &>div:first-of-type {
            margin-right: 16px;
        }
    }

    &>p {
        color: var(--night-black-100, #25252D);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }
}

.preview-shop {
    border-radius: 12px;
    background: white;
    margin: 20px 0px;
    margin-bottom: 0px !important;

    & .branding-container-tracking-right-view-tabs {

        padding: 16px 0px 16px 16px;
    }
}

.branding-container-tracking-right-laptop-body-content-shop-flex-item.tracking-shop-item {
    margin-right: 12px;
    border-radius: 8px;
    overflow: hidden;

    & img {
        // width: 150px;
        // min-height: 150px;
        max-height: 150px;
        width: 100%;
    }
}

.tracking-content-social {
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #25252D;
    padding: 16px;
    margin-top: 16px;
    box-shadow: 0px 0px 45.82666778564453px 0px rgba(208, 207, 207, 0.08);

    @media (max-width: 500px) {
        flex-direction: column;
        align-items: flex-start;

    }

    &>div {
        display: flex;

        @media (max-width: 500px) {
            margin-top: 24px;

        }

        &>div {
            margin-left: 8px;
        }
    }

    &>p {
        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        max-width: 253px;
        margin: 0;
    }
}

.tracking-items-socials>a {
    cursor: pointer;
}

.slick-track {
    display: flex !important;
    // gap: 16px;


    &>div {
        width: 150px;
    }
}

.brand-banner-tracking {
    width: 100%;
    max-width: unset;
    position: relative;
    // min-height: 150px;
    max-height: 150px;

    border-radius: 12px;
    overflow: hidden;

    & img {
        border-radius: 12px;
        // height: 150px;
        width: 100%;
    }

    &>div {
        display: inline-flex;
        background: white;
        padding: 8px;
        position: absolute;
        bottom: 16px;
        left: 16px;

        & p {
            color: #2B2B34;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            margin: 0;
        }




    }

}

.tracking-content-social.flex {
    display: flex;
    justify-content: center;
    align-items: center;

    &>p {
        text-align: center;
    }
}

.custom-courier-tracking-text {
    font-weight: 600;
    font-size: 24px;
    line-height: 32.78px;
    color: #333333;
    margin-bottom: 20px;
}

.cancelled {
    background: #fbeaed;
    color: #333333;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.12px;
    padding: 4px 8px;
    border-radius: 500px;
    display: inline-flex;
    width: fit-content;
    white-space: nowrap;
    text-transform: capitalize;
    gap: 5px;
    align-items: center;
    justify-content: center;
}


.scheduled,
.processing {
    background: #f2d2b5;
    color: #333333;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.12px;
    padding: 4px 8px;
    border-radius: 500px;
    display: inline-flex;
    width: fit-content;
    white-space: nowrap;
    text-transform: capitalize;
    gap: 5px;
    align-items: center;
    justify-content: center;
}

.delivered,
.completed,
.paid {
    color: #333333;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.12px;
    padding: 4px 8px;
    background: #E1F4EC;
    border-radius: 500px;
    display: inline-flex;
    width: fit-content;
    white-space: nowrap;
    text-transform: capitalize;
    gap: 5px;
    align-items: center;
    justify-content: center;
}


.picked,
.up {
    color: #333333;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.12px;
    padding: 4px 8px;
    background: rgba(152, 85, 174, 0.25);
    border-radius: 500px;
    display: inline-flex;
    width: fit-content;
    white-space: nowrap;
    text-transform: capitalize;
    gap: 5px;
    align-items: center;
    justify-content: center;
}

.confirmed {
    background: #d7e2f4;
    color: #333333;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.12px;
    padding: 4px 8px;
    border-radius: 500px;
    display: inline-flex;
    width: fit-content;
    white-space: nowrap;
    text-transform: capitalize;
    gap: 5px;
    align-items: center;
    justify-content: center;
}

.pending {
    color: #333333;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.12px;
    padding: 4px 8px;
    background: #FBF7E2;
    border-radius: 500px;
    display: inline-flex;
    width: fit-content;
    white-space: nowrap;
    text-transform: capitalize;
    gap: 5px;
    align-items: center;
    justify-content: center;
}



.in {
    background: #d4f2f7;
    color: #333333;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.12px;
    padding: 4px 8px;
    border-radius: 500px;
    display: inline-flex;
    width: fit-content;
    white-space: nowrap;
    text-transform: capitalize;
    gap: 5px;
    align-items: center;
    justify-content: center;
}

.custom-courier-tracking-tag {
    margin-bottom: 20px;
}